/** @jsxImportSource @emotion/react */
import { PanelHeader } from 'futurekit'
import { StyledRow, styles } from './Header.styles'

const Header = ({ children }) => {
  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <StyledRow css={styles.row}>{children}</StyledRow>
      </div>
    </>
  )
}

export { Header }
