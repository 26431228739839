const INITIAL_MAIN_PANEL_OVERFLOW_VALUE = 'auto'

const HEIGHT_180 = 180
const HEIGHT_250 = 250
const HEIGHT_300 = 300
const HEIGHT_650 = 650
const HEIGHT_720 = 720
const HEIGHT_780 = 780
const HEIGHT_850 = 850

const VIEWER_ROLE_NAME = 'VIEWER'
const VIEWER_ROLE_PRIORITY = 25
const EDITOR_ROLE_NAME = 'EDITOR'
const EDITOR_ROLE_PRIORITY = 50
const ADVANCED_ROLE_NAME = 'ADVANCED'
const ADVANCED_ROLE_PRIORITY = 75
const ADMIN_ROLE_NAME = 'ADMIN'
const ADMIN_ROLE_PRIORITY = 100

const READ_ONLY_FIELDS = [
  'telefonica_value',
  'regla',
  'verify',
  'created_time',
  'user_latest',
  'pseudocode',
  'error',
  'rule_id',
  'modified_time',
]

const EDITABLE_STATUS = ['EDIT', 'REVIEW']

export {
  INITIAL_MAIN_PANEL_OVERFLOW_VALUE,
  HEIGHT_180,
  HEIGHT_250,
  HEIGHT_300,
  HEIGHT_650,
  HEIGHT_720,
  HEIGHT_780,
  HEIGHT_850,
  VIEWER_ROLE_NAME,
  VIEWER_ROLE_PRIORITY,
  EDITOR_ROLE_NAME,
  EDITOR_ROLE_PRIORITY,
  ADVANCED_ROLE_NAME,
  ADVANCED_ROLE_PRIORITY,
  ADMIN_ROLE_NAME,
  ADMIN_ROLE_PRIORITY,
  READ_ONLY_FIELDS,
  EDITABLE_STATUS,
}
