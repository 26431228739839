/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Container,
  Collapse,
  Nav,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { useAdminNavbarController } from './AdminNavbar.controller'
import { styles, StyledDropdown } from './AdminNavbar.styles'

const AdminNavbar = ({ activeRouteName }) => {
  const {
    user,
    color,
    isOpen,
    isDropdownOpen,
    sidebarToggleRef,
    toggleSidebar,
    toggleDropdownToggle,
    handleHamburgerMenuClick,
    handleChangePasswordOption,
    logOut,
  } = useAdminNavbarController()

  const navbarClassNames = classnames('navbar-absolute', {
    'navbar-transparent': window.location.href.indexOf('full-screen-maps') === -1 && color === 'transparent',
  })

  return (
    <Navbar
      expand="lg"
      className={navbarClassNames}
      color={window.location.href.indexOf('full-screen-maps') !== -1 ? 'white' : color}
    >
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button type="button" ref={sidebarToggleRef} className="navbar-toggler" onClick={toggleSidebar}>
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <NavbarBrand href="/rules/validation">{activeRouteName}</NavbarBrand>
        </div>
        <NavbarToggler onClick={handleHamburgerMenuClick}>
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          <Nav navbar>
            <StyledDropdown nav isOpen={isDropdownOpen} toggle={toggleDropdownToggle} css={styles.dropdown}>
              <DropdownToggle caret nav>
                <i className="now-ui-icons users_single-02" />
                {!!user && !!user.email && <p>{user.email}</p>}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={handleChangePasswordOption}>Change password</DropdownItem>
                <DropdownItem onClick={logOut}>Log out</DropdownItem>
              </DropdownMenu>
            </StyledDropdown>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}

AdminNavbar.defaultProps = {
  activeRouteName: '',
}

AdminNavbar.propTypes = {
  activeRouteName: PropTypes.string,
}

export { AdminNavbar }
