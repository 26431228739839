import { useMemo, useCallback, useState, useEffect } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getBaselines, getParametersByBaseline, getParameterFilterByBaseline, updateParameter } from 'api'
import { showToast } from 'utils'

export const useParameterSelectorController = ({
  selectedBaseline,
  selectedParameters,
  selectionLimit,
  selectParameter,
  addParameter,
  overloadSelectParameter,
}) => {
  const [visibleRows, setVisibleRows] = useState([])

  const { data: baselines, isLoading: isBaselinesLoading } = useQuery(['baselines'], getBaselines)
  const {
    data: parameters,
    isLoading: isParametersLoading,
    fetchStatus: fetchParametersStatus,
  } = useQuery(['parameters', selectedBaseline?.value], getParametersByBaseline, {
    enabled: !!selectedBaseline,
  })
  const {
    data: parameterFilter,
    isLoading: isParameterFilterLoading,
    fetchStatus: fetchParameterFilterStatus,
  } = useQuery(['parameterFilter', selectedBaseline?.value], getParameterFilterByBaseline, {
    enabled: !!selectedBaseline,
  })

  const baselineOptions = useMemo(() => {
    if (!baselines || isBaselinesLoading) {
      return []
    }
    return baselines.map(bl => {
      return {
        value: bl.baseline_id,
        label: `${bl.manufacturer}  ${bl.technology}  ${bl.software_version}  ${bl.baseline_version}  ${bl.hardware_type}`,
        owner_id: bl.owner_id,
        status_id: bl.status,
        status_name: bl.status_name,
      }
    })
  }, [baselines, isBaselinesLoading])

  const selectedParameterIds = useMemo(() => {
    if (selectionLimit === 1) {
      return selectedParameters[0] ? [selectedParameters[0].rule_id] : []
    }
    return selectedParameters.map(parameter => parameter.rule_id)
  }, [selectedParameters, selectionLimit])

  const handleRowClick = useCallback(
    parameter => {
      if (
        !!overloadSelectParameter &&
        selectionLimit === selectedParameters.length &&
        !selectedParameters.includes(parameter)
      ) {
        overloadSelectParameter(parameter)
      } else if (selectionLimit > 1 && !!addParameter && !selectedParameters.includes(parameter)) {
        addParameter(parameter)
      } else if (!selectedParameters.includes(parameter)) {
        selectParameter(parameter)
      }
    },
    [addParameter, selectParameter, selectionLimit, overloadSelectParameter, selectedParameters]
  )

  const { mutate: update } = useMutation(updateParameter, {
    onSuccess: response => {
      showToast(response.message)
    },
  })

  const handleSwitch = useCallback(
    cell => {
      update({
        ruleId: cell.row.original.rule_id,
        [cell.column.id]: !cell.row.values[cell.column.id],
      })
    },
    [update]
  )

  useEffect(() => {
    setVisibleRows([])
  }, [selectedBaseline])

  return {
    excelData: visibleRows,
    parameters,
    parameterFilter,
    baselineOptions,
    isLoading:
      isParametersLoading ||
      fetchParametersStatus === 'fetching' ||
      isParameterFilterLoading ||
      fetchParameterFilterStatus === 'fetching',
    isBaselinesLoading,
    selectedParameterIds,
    setVisibleRows,
    handleRowClick,
    handleSwitch,
  }
}
