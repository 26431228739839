/** @jsxImportSource @emotion/react */
import { Header, Card, ParameterSelector } from 'components'
import { RulesProvider } from 'context'
import { EDITOR_ROLE_PRIORITY } from 'utils/constants'
import ParameterForm from './components/ParameterForm'
import History from './components/History'
import { StyledCol, styles } from './ParameterEditor.styles'
import { useParameterEditorController } from './ParameterEditor.controller'

const ParameterEditorState = ({ children }) => {
  return <RulesProvider maxNumberSelectedParameters={1}>{children}</RulesProvider>
}

const ParameterEditorContent = () => {
  const {
    transition,
    selectedBaseline,
    selectedParameters,
    selectBaseline,
    selectParameter,
    selectCell,
    isParameterSelected,
    cardTitle,
    isReadOnly,
  } = useParameterEditorController()

  return (
    <>
      <StyledCol css={styles.col(!!isParameterSelected)}>
        <Card title="Parameter table" cardHeight={{ largeDesktop: 'minimized' }}>
          <ParameterSelector
            highlightCellSelection
            selectedBaseline={selectedBaseline}
            selectedParameters={selectedParameters}
            selectBaseline={selectBaseline}
            selectParameter={selectParameter}
            selectCell={selectCell}
            isSwitchColumnReadOnly={isReadOnly}
          />
        </Card>
      </StyledCol>
      <StyledCol css={styles.col(isParameterSelected, transition)}>
        <Card title={cardTitle} cardHeight={{ largeDesktop: 'minimized' }}>
          <ParameterForm />
        </Card>
      </StyledCol>
      <StyledCol xs={12}>
        <Card title="Baseline History" cardHeight={{ largeDesktop: 'minimized' }} priority={EDITOR_ROLE_PRIORITY}>
          <History />
        </Card>
      </StyledCol>
    </>
  )
}

export const ParameterEditor = () => {
  return (
    <ParameterEditorState>
      <Header>
        <ParameterEditorContent />
      </Header>
    </ParameterEditorState>
  )
}
