/** @jsxImportSource @emotion/react */
import { useMemo } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, Input } from 'reactstrap'
import { DnDContainer } from '../DndContainer'
import { useToolbarController } from './Toolbar.controller'
import {
  StyledToolbar,
  StyledButton,
  StyledIcon,
  StyledDropdownOption,
  StyledRowNumber,
  styles,
} from './Toolbar.styles'

const Toolbar = ({
  rowNumber,
  defaultCols,
  toolbarOptions,
  columnsOptions,
  visibleColumns,
  handleVisibleColumnsChange,
  reorderVisibleColumns,
}) => {
  const { dropdownVisibility, handleFilterClick, handleOrderClick } = useToolbarController()

  const filter = useMemo(
    () =>
      // filter functionality only lives with dynamic columns for the time being
      toolbarOptions.showFilter && columnsOptions.dynamicColumns ? (
        <Dropdown isOpen={dropdownVisibility.filter} toggle={handleFilterClick}>
          <DropdownToggle data-toggle="dropdown" tag="div">
            <StyledButton css={styles.toolbarButton} color="link">
              <StyledIcon css={styles.toolbarIcon} className="fas fa-filter" name="filter" />
            </StyledButton>
          </DropdownToggle>
          <DropdownMenu css={styles.dropdownMenu}>
            {visibleColumns.map(option => (
              <StyledDropdownOption css={styles.dropdownOption} key={option.id}>
                <Input
                  type="checkbox"
                  id={option.id}
                  disabled={defaultCols.some(column => column.accessor === option.id)}
                  checked={option.value}
                  onChange={handleVisibleColumnsChange}
                />
                <span>{option.label}</span>
              </StyledDropdownOption>
            ))}
          </DropdownMenu>
        </Dropdown>
      ) : null,
    [
      toolbarOptions.showFilter,
      columnsOptions.dynamicColumns,
      dropdownVisibility.filter,
      handleFilterClick,
      visibleColumns,
      defaultCols,
      handleVisibleColumnsChange,
    ]
  )

  const order = useMemo(
    () =>
      toolbarOptions.showOrder ? (
        <Dropdown isOpen={dropdownVisibility.order} toggle={handleOrderClick}>
          <DropdownToggle data-toggle="dropdown" tag="div">
            <StyledButton css={styles.toolbarButton} color="link">
              <StyledIcon css={styles.toolbarIcon} className="fas fa-sort-amount-down" name="order" />
            </StyledButton>
          </DropdownToggle>
          <DropdownMenu css={styles.dropdownMenu}>
            <DnDContainer visibleColumns={visibleColumns} reorderVisibleColumns={reorderVisibleColumns} />
          </DropdownMenu>
        </Dropdown>
      ) : null,
    [toolbarOptions.showOrder, dropdownVisibility.order, handleOrderClick, visibleColumns, reorderVisibleColumns]
  )

  const rows = useMemo(
    () =>
      toolbarOptions.showRowNumber ? (
        <StyledRowNumber css={styles.rowNumber}>{`${rowNumber} row${rowNumber === 1 ? '' : 's'}`}</StyledRowNumber>
      ) : null,
    [toolbarOptions.showRowNumber, rowNumber]
  )

  if (!filter && !order) {
    return null
  }
  return (
    <StyledToolbar css={styles.toolbar}>
      {filter}
      {order}
      {rows}
    </StyledToolbar>
  )
}

export { Toolbar }
