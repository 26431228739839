/** @jsxImportSource @emotion/react */
import { useMemo } from 'react'
import { Col } from 'reactstrap'
import Select from 'react-select'
import { FutureTable } from 'futurekit'
import { Spinner } from '../Spinner'
import { EmptyState } from '../EmptyState'
import { Status } from '../Status'
import { ExcelGenerator } from '../ExcelGenerator'
import { useParameterSelectorController } from './ParameterSelector.controller'
import { StyledRow, StyledCol, styles } from './ParameterSelector.styles'

const ParameterSelector = ({
  hideMainVerticalScroll = false,
  highlightCellSelection = false,
  selectedBaseline,
  selectedParameters,
  selectionLimit = 1,
  selectBaseline,
  selectParameter,
  selectCell,
  addParameter = null,
  overloadSelectParameter = null,
  isSwitchColumnReadOnly,
}) => {
  const {
    excelData,
    parameters,
    parameterFilter,
    baselineOptions,
    isLoading,
    isBaselinesLoading,
    selectedParameterIds,
    setVisibleRows,
    handleRowClick,
    handleSwitch,
  } = useParameterSelectorController({
    selectedBaseline,
    selectedParameters,
    selectionLimit,
    selectParameter,
    addParameter,
    overloadSelectParameter,
  })

  const table = useMemo(() => {
    if (selectedBaseline && isLoading) {
      return <Spinner />
    }
    if (!parameters || !parameters.length || !parameterFilter || !Object.keys(parameterFilter).length) {
      return <EmptyState size="5rem" />
    }
    return (
      <FutureTable
        data={parameters}
        globalFilter={parameterFilter}
        selectedRowIds={selectedParameterIds}
        rowIdKey="rule_id"
        onRowClick={handleRowClick}
        onCellClick={selectCell}
        onRowsChange={setVisibleRows}
        tableConfig={{
          hideMainVerticalScroll,
          usePagination: true,
        }}
        columnsConfig={{
          isStriped: false,
          isVirtualized: true,
          columnsExcluded: ['rule_id', 'baseline_id'],
          selectionLimit: overloadSelectParameter ? selectionLimit + 1 : selectionLimit,
          highlightSelection: true,
          highlightCellSelection: highlightCellSelection && !!selectedParameterIds.length,
          autoSelection: selectionLimit === 1,
          dynamicColumns: true,
          advancedFilter: true,
        }}
        switchConfig={{
          isVisible: true,
          columnId: 'draft',
          callback: handleSwitch,
          isReadOnly: isSwitchColumnReadOnly,
        }}
        toolbarConfig={{
          showFilter: true,
          showOrder: true,
          showRowNumber: true,
        }}
        css={styles.table}
      />
    )
  }, [
    selectedBaseline,
    isLoading,
    parameters,
    parameterFilter,
    selectedParameterIds,
    handleRowClick,
    selectCell,
    setVisibleRows,
    hideMainVerticalScroll,
    overloadSelectParameter,
    selectionLimit,
    highlightCellSelection,
    handleSwitch,
    isSwitchColumnReadOnly,
  ])

  return (
    <>
      <StyledRow css={styles.row}>
        <Col md="4" sm="6" xs="12">
          <Select
            className="react-select primary"
            classNamePrefix="react-select"
            name="pageSelect"
            options={baselineOptions}
            value={selectedBaseline}
            onChange={selectBaseline}
            isDisabled={!!isBaselinesLoading || !baselineOptions.length}
            placeholder="Select a Baseline"
          />
        </Col>
        {selectedBaseline && !isLoading && (
          <Col md="4" sm="6" xs="12">
            <Status name={selectedBaseline.status_name} />
          </Col>
        )}
        {!!parameters && (
          <StyledCol css={styles.col}>
            <ExcelGenerator
              collection={excelData}
              columnsToSkip={['rule_id']}
              excelName="baseline"
              sheetName="parameters"
            />
          </StyledCol>
        )}
      </StyledRow>
      {table}
    </>
  )
}

export { ParameterSelector }
