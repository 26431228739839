import styled from '@emotion/styled'

export const StyledTableWrapper = styled.div``
const tableWrapperStyles = disabled => ({
  pointerEvents: disabled ? 'none' : 'auto',
  height: '100%',
})

export const StyledTableRow = styled.tr``
const tableRowStyles = applyHoverStyle => ({
  height: '3rem',
  maxHeight: '3rem',
  ...(applyHoverStyle && {
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.05)',
    },
  }),
})

export const StyledPaginationWrapper = styled.div``
const paginationStyles = {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  columnGap: 3,
  marginTop: 10,

  '& button:hover': {
    cursor: 'pointer',
  },
}

const paginationButtonStyles = {
  border: 0,
  minWidth: 30,
  height: 30,
  paddingTop: 5,
  color: '#2c2c2c',
  background: 'transparent',
  '&:disabled': {
    height: 15,
    paddingTop: 0,
    color: '#9a9a9a',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
}

const paginationIconStyles = {
  fontSize: 16,
}

const paginationInfoStyles = {
  fontSize: 15,
  fontWeight: 700,
  fontFamily: 'Montserrat',
  color: '#2c2c2c',
  margin: '0 8px',
}

export const styles = {
  tableWrapper: tableWrapperStyles,
  tableRow: tableRowStyles,
  pagination: paginationStyles,
  paginationButton: paginationButtonStyles,
  paginationIcon: paginationIconStyles,
  paginationInfo: paginationInfoStyles,
}
