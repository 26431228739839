import { useContext, useState, useRef, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { GlobalContext } from 'context'
import { changePassword as changePasswordApi } from 'api'
import { showChangePasswordAlert, showSuccessAlert } from 'utils'

export const useAdminNavbarController = () => {
  const location = useLocation()
  const { user, logOut } = useContext(GlobalContext)

  const { mutateAsync: changePassword } = useMutation(changePasswordApi, {
    onSuccess: res => {
      showSuccessAlert({
        title: res.message,
        onClose: logOut,
      })
    },
  })

  const sidebarToggleRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [color, setColor] = useState('transparent')

  const toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open')
    sidebarToggleRef.current.classList.toggle('toggled')
  }

  const toggleDropdownToggle = () => setIsDropdownOpen(prevState => !prevState)

  const handleHamburgerMenuClick = () => {
    setColor(isOpen ? 'transparent' : 'white')
    setIsOpen(prevState => !prevState)
  }

  const handleChangePassword = useCallback(async () => {
    const alertValues = {
      currentPassword: document.getElementById('currentPassword')?.value,
      newPassword: document.getElementById('newPassword')?.value,
    }
    try {
      await changePassword({
        old_password: alertValues.currentPassword,
        new_password: alertValues.newPassword,
        new_password_confirm: alertValues.newPassword,
      })
    } catch (error) {
      // error handled on api interceptor
    }
  }, [changePassword])

  const handleChangePasswordOption = () => showChangePasswordAlert({ callbackAsyncFn: handleChangePassword })

  useEffect(() => {
    const updateColor = () => setColor(window.innerWidth < 993 && isOpen ? 'white' : 'transparent')

    window.addEventListener('resize', updateColor)
    return () => {
      window.removeEventListener('resize', updateColor)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (window.innerWidth < 993 && document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open')
      sidebarToggleRef.current.classList.toggle('toggled')
    }
  }, [location])

  return {
    user,
    color,
    isOpen,
    isDropdownOpen,
    sidebarToggleRef,
    toggleSidebar,
    toggleDropdownToggle,
    handleHamburgerMenuClick,
    handleChangePasswordOption,
    logOut,
  }
}
