const rulesReducer = (state, action) => {
  switch (action.type) {
    case 'SELECT_BASELINE':
      return {
        ...state,
        selectedBaseline: action.payload.baseline,
        selectedStatus: action.payload.selectedStatus,
        userIsOwnerForSelectedBaseline: action.payload.baseline.owner === action.payload.userId,
        selectedParameters: [],
        keyWordFilter: '',
      }
    case 'SELECT_PARAMETER':
      return {
        ...state,
        selectedParameters: action.payload,
        keyWordFilter: '',
      }
    case 'REMOVE_PARAMETER':
      return {
        ...state,
        selectedParameters: state.selectedParameters.filter(param => param.rule_id !== action.payload),
        keyWordFilter: '',
      }
    case 'REMOVE_ALL_PARAMETERS':
      return {
        ...state,
        selectedParameters: [],
        keyWordFilter: '',
        selectedCell: null,
      }
    case 'OVERLOAD_PARAMETER':
      return {
        ...state,
        selectedParameters: [...state.selectedParameters.slice(1), action.payload],
        keyWordFilter: '',
      }
    case 'ADD_KEYWORD_FILTER':
      return {
        ...state,
        keyWordFilter: action.payload,
      }
    case 'SELECT_CELL':
      return {
        ...state,
        selectedCell: action.payload,
      }
    default:
      return state
  }
}

export { rulesReducer }
