import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Global, css } from '@emotion/react'
import { LoginLayout, Spinner } from 'components'
import { GlobalProvider } from 'context'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useAppStateController } from 'App.controller'
import Login from 'views/Login'
import { useRoutePaths } from 'routes'
import { getToken } from 'utils'
import { INITIAL_MAIN_PANEL_OVERFLOW_VALUE } from 'utils/constants'
import ErrorBoundary from './ErrorBoundary'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

const App = () => {
  const globalStyles = css`
    html {
      overflow: hidden;
    }
    .main-panel {
      height: 100vh;
      overflow: ${INITIAL_MAIN_PANEL_OVERFLOW_VALUE};
    }
    *::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    *::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #878787;
    }
    *::-webkit-scrollbar-track {
      display: none:
      border-radius: 10px;
      background-color: #fafafa;
    }
  `

  return (
    <QueryClientProvider client={queryClient}>
      <Global styles={globalStyles} />
      <ErrorBoundary>
        <AppState>
          <AppRoutes />
        </AppState>
      </ErrorBoundary>
    </QueryClientProvider>
  )
}

const AppState = ({ children }) => {
  const token = getToken()
  const { user, roles, isLoading } = useAppStateController(token)

  if (token && (!user || !roles || isLoading)) {
    return <Spinner height="90vh" size="7rem" />
  }
  return (
    <GlobalProvider user={user} roles={roles}>
      {children}
    </GlobalProvider>
  )
}

const AppRoutes = () => {
  const { appRoutes } = useRoutePaths()

  return (
    <BrowserRouter>
      <Switch>
        {appRoutes}
        <Route path="/rules">
          <Redirect to="/rules/validation" />
        </Route>
        <Route path="/login">
          <LoginLayout>
            <Login />
          </LoginLayout>
        </Route>
        <Route>
          <Redirect to="/rules" />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default App
