/** @jsxImportSource @emotion/react */
import { Header, Card } from 'components'
import { CRUDManagementProvider } from 'context'
import { Col } from 'reactstrap'
import UserList from './components/UserList'
import UserForm from './components/UserForm'
import Toolbar from './components/Toolbar'

const UserEditorState = ({ children }) => {
  return <CRUDManagementProvider>{children}</CRUDManagementProvider>
}

const UserEditorContent = () => {
  return (
    <>
      <Col xl={8}>
        <Card title="User table">
          <UserList />
        </Card>
      </Col>
      <Col xl={4}>
        <Card title="User details">
          <Toolbar />
          <UserForm />
        </Card>
      </Col>
    </>
  )
}

export const UserEditor = () => {
  return (
    <UserEditorState>
      <Header>
        <UserEditorContent />
      </Header>
    </UserEditorState>
  )
}
