import styled from '@emotion/styled'
import { Row } from 'reactstrap'

export const StyledRow = styled(Row)``

const rowStyles = {
  display: 'flex',
  overflow: 'hidden',
  '@media (max-width: 1200px)': {
    flexDirection: 'column ',
  },
}

export const styles = {
  row: rowStyles,
}
