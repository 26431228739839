import styled from '@emotion/styled'

export const StyledTableHead = styled.thead``

const tableHeadStyles = filterable => ({
  marginBottom: filterable ? '2rem' : 0,
})

export const styles = {
  tableHead: tableHeadStyles,
}
