/** @jsxImportSource @emotion/react */
import { useState, useRef, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { NavLink } from 'react-router-dom'
import { Collapse } from 'reactstrap'
import logo from 'futurekit/core/assets/img/FC_logo.png'
import logoMin from 'futurekit/core/assets/img/FC_favicon.png'
import { GlobalContext } from 'context'
import { StyledNav, StyledElementList, styles } from './Sidebar.styles'

const Sidebar = ({ routeList, backgroundColor }) => {
  const [collapseStates, setCollapseStates] = useState({})
  const { isUserAdmin } = useContext(GlobalContext)
  const sidebar = useRef()

  const getCollapseInitialState = routes => {
    for (let i = 0; i < routes.length; i += 1) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true
      }
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true
      }
    }
    return false
  }

  const getCollapseStates = routes => {
    let initialState = {}
    routes.map(prop => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        }
      }
      return null
    })
    return initialState
  }

  const activeRoute = routeName => {
    return window.location.href.indexOf(routeName) > -1 ? 'active' : ''
  }

  const createLinks = routes => {
    return routes.map((prop, i) => {
      if (prop.collapse) {
        const st = {
          [prop.state]: !collapseStates[prop.state],
        }

        return (
          <li className={getCollapseInitialState(prop.views) ? 'active' : ''} key={uuidv4()}>
            <a
              href="/"
              data-toggle="collapse"
              aria-expanded={collapseStates[prop.state]}
              onClick={e => {
                e.preventDefault()
                setCollapseStates(st)
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={collapseStates[prop.state]}>
              <ul className="nav">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        )
      }

      return (
        <StyledElementList
          className={activeRoute(prop.path)}
          key={uuidv4()}
          css={styles.elementList(routes.length === i + 1, isUserAdmin)}
        >
          <NavLink to={prop.path} activeClassName="">
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </StyledElementList>
      )
    })
  }

  useEffect(() => {
    setCollapseStates(getCollapseStates(routeList))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="sidebar" data-color={backgroundColor}>
      <div className="sidebar-back-button">
        <a href="/">
          <i id="back-button" className="fas fa-angle-left" /> Back
        </a>
      </div>
      <div className="logo">
        {document.body.classList.contains('sidebar-mini') && (
          <a href="/" className="simple-text logo-mini" target="_blank" rel="noreferrer">
            <div className="logo-img">
              <img src={logoMin} alt="future-logo" />
            </div>
          </a>
        )}
        <a href="/" className="simple-text logo-normal" target="_blank" rel="noreferrer">
          <div>
            <img src={logo} alt="future-logo" />
          </div>
        </a>
        <span id="gradient" className="border-bottom" />
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        <StyledNav css={styles.nav}>{createLinks(routeList)}</StyledNav>
      </div>
    </div>
  )
}

Sidebar.defaultProps = {
  routeList: [],
  backgroundColor: 'blue',
}

Sidebar.propTypes = {
  routeList: PropTypes.arrayOf(PropTypes.shape({})),
  backgroundColor: PropTypes.oneOf(['blue', 'yellow', 'green', 'orange', 'red', 'future']),
}

export { Sidebar }
