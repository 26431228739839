import { useState } from 'react'
import defaultImage from 'futurekit/core/assets/img/default-avatar.png'

const PictureUpload = () => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage)

  const handleImageChange = e => {
    e.preventDefault()
    const reader = new FileReader()
    const file = e.target.files[0]
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result)
    }
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  return (
    <div className="picture-container">
      <div className="picture">
        <img src={imagePreviewUrl} className="picture-src" alt="..." />
        <input type="file" onChange={e => handleImageChange(e)} />
      </div>
      <h6 className="description">Choose Picture</h6>
    </div>
  )
}

export { PictureUpload }
