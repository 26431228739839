import PropTypes from 'prop-types'

const PanelHeader = ({ size, content }) => {
  return <div className={`panel-header ${size !== undefined ? `panel-header-${size}` : ''}`}>{content}</div>
}

PanelHeader.defaultProps = {
  size: undefined,
  content: null,
}

PanelHeader.propTypes = {
  size: PropTypes.oneOf(['sm', 'lg', undefined]),
  content: PropTypes.node,
}

export { PanelHeader }
