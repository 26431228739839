/** @jsxImportSource @emotion/react */
import { useContext, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AdminNavbar, Footer, Sidebar } from 'futurekit'
import { GlobalContext } from 'context'
import { StyledWrapper, styles } from './Layout.styles'

const Layout = ({ children, routeList, activeRouteName }) => {
  const { toggleFutureViews } = useContext(GlobalContext)

  const location = useLocation()
  const mainPanel = useRef()

  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    mainPanel.current.scrollTop = 0
  }, [location])

  return (
    <StyledWrapper css={styles.wrapper}>
      <Sidebar routeList={routeList} backgroundColor="future" />
      <div className="main-panel" ref={mainPanel}>
        <AdminNavbar activeRouteName={activeRouteName} toggleFutureViews={toggleFutureViews} />
        {children}
        {window.location.href.indexOf('full-screen-maps') !== -1 ? null : <Footer fluid />}
      </div>
    </StyledWrapper>
  )
}

export { Layout }
