/** @jsxImportSource @emotion/react */
import { Header, Card } from 'components'
import { Col } from 'reactstrap'
import { BaselineHistoryProvider, CRUDManagementProvider } from 'context'
import Filter from './components/Filter'
import List from './components/List'

const BaselineHistoryState = ({ children }) => {
  return (
    <CRUDManagementProvider>
      <BaselineHistoryProvider>{children}</BaselineHistoryProvider>
    </CRUDManagementProvider>
  )
}

const BaselineHistoryContent = () => {
  return (
    <Col>
      <Card title="Baseline History">
        <Filter />
        <List />
      </Card>
    </Col>
  )
}

export const BaselineHistory = () => {
  return (
    <BaselineHistoryState>
      <Header>
        <BaselineHistoryContent />
      </Header>
    </BaselineHistoryState>
  )
}
