import styled from '@emotion/styled'
import { Button } from 'reactstrap'

export const StyledButton = styled(Button)``

const buttonStyles = {
  margin: 0,
  minWidth: '92px',
  minHeight: '38px',
}

export const styles = {
  button: buttonStyles,
}
