import styled from '@emotion/styled'
import { Dropdown } from 'reactstrap'

export const StyledDropdown = styled(Dropdown)``

const dropdownStyles = {
  '& div': {
    '@media (max-width: 991px)': {
      position: 'static !important',
    },
  },
}

export const styles = {
  dropdown: dropdownStyles,
}
