import styled from '@emotion/styled'
import { Nav } from 'reactstrap'

export const StyledNav = styled(Nav)``

export const StyledElementList = styled.li``

const navStyles = {
  position: 'relative',
  height: '100%',
}

const elementListStyles = (isTheLastOne, isUserAdmin) => ({
  width: '100%',

  '@media (min-height: 900px)': {
    position: isTheLastOne && isUserAdmin ? 'absolute' : 'initial',
    bottom: 0,
  },
})

export const styles = {
  nav: navStyles,
  elementList: elementListStyles,
}
