import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const StyledWrapper = styled.div``

const wrapperStyles = {
  animation: `${fadeIn} 0.75s`,
}

export const styles = {
  wrapper: wrapperStyles,
}
