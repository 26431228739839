import styled from '@emotion/styled'

export const StyledTableBody = styled.tbody``

const tableBodyStyles = disabled => ({
  opacity: disabled ? 0.2 : 1,
})

const tableCellStyles = (selectedCell, columnId, rowId) => {
  if (selectedCell?.columnId === columnId && selectedCell?.rowId === rowId) {
    return {
      minHeight: 49,
      paddingTop: '13px !important',
      border: '1px solid #828282',
      backgroundColor: 'rgb(222, 222, 222)',
    }
  }
  return null
}

const switchContainerStyles = {
  textAlign: 'center',
  '& > div': {
    margin: 'auto',
  },
}

export const styles = {
  tableBody: tableBodyStyles,
  tableCell: tableCellStyles,
  switchContainer: switchContainerStyles,
}
