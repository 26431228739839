/** @jsxImportSource @emotion/react */
import { Header, Card } from 'components'
import { Col } from 'reactstrap'
import { CRUDManagementProvider } from 'context'
import BaselineList from './components/BaselineList'
import BaselineForm from './components/BaselineForm'
import BaselineStatusHistory from './components/BaselineStatusHistory'
import Toolbar from './components/Toolbar'

const BaselineEditorState = ({ children }) => {
  return <CRUDManagementProvider>{children}</CRUDManagementProvider>
}

const BaselineEditorContent = () => {
  return (
    <>
      <Col xl={8}>
        <Card title="Baseline table" cardHeight={{ largeDesktop: 'minimized' }}>
          <BaselineList />
        </Card>
      </Col>
      <Col xl={4}>
        <Card title="Baseline details" cardHeight={{ largeDesktop: 'minimized' }}>
          <Toolbar />
          <BaselineForm />
        </Card>
      </Col>
      <Col xl={12}>
        <Card title="Baseline Status History" cardHeight={{ largeDesktop: 'minimized' }}>
          <BaselineStatusHistory />
        </Card>
      </Col>
    </>
  )
}

export const BaselineEditor = () => {
  return (
    <BaselineEditorState>
      <Header>
        <BaselineEditorContent />
      </Header>
    </BaselineEditorState>
  )
}
