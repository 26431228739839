/** @jsxImportSource @emotion/react */
import { useMemo } from 'react'
import { Dropdown, DropdownToggle, Input } from 'reactstrap'
import { useColumnHeaderController } from './ColumnHeader.controller'
import {
  StyledTableHeader,
  StyledHeaderContent,
  StyledIcon,
  StyledInput,
  StyledHeaderText,
  StyledToolbar,
  StyledDropdownMenu,
  StyledDropdownOption,
  StyledInputContainer,
  StyledOptionContainer,
  styles,
} from './ColumnHeader.styles'

const ColumnHeader = ({
  column,
  columnsOptions,
  switchOptions,
  filterNameReachable,
  filterOptions,
  isSomeColumnFilterable,
  isLaterColumn,
  handleGlobalFilterChange,
  handleGlobalFilterSelectAll,
  handleGlobalFilterDeselectAll,
}) => {
  const {
    isTooltip,
    sortable,
    sortDirection,
    columnFilter,
    isAdvancedFilterDropdownVisible,
    searchValue,
    handleSelectAll,
    handleInputChange,
    handleSearch,
    toggleAdvancedFilterDropdownVisibility,
  } = useColumnHeaderController({
    column,
    columnsOptions,
    filterNameReachable,
    handleGlobalFilterChange,
    handleGlobalFilterSelectAll,
    handleGlobalFilterDeselectAll,
  })

  const isSwitchColumn = switchOptions.isVisible && column.id === switchOptions.columnId

  const advancedFilter = useMemo(() => {
    if (!columnsOptions.advancedFilter) {
      return null
    }

    const options = []
    if (isAdvancedFilterDropdownVisible) {
      Object.entries(filterOptions).forEach(([value, visible]) => {
        if (value.toLowerCase().includes(searchValue.toLowerCase())) {
          options.push(
            <StyledDropdownOption css={styles.dropdownOption} key={value}>
              <StyledInputContainer css={styles.inputContainer}>
                <Input type="checkbox" id={value} checked={visible} onChange={handleInputChange} />
              </StyledInputContainer>
              <StyledOptionContainer css={styles.optionContainer}>
                <span title={value || 'Empty fields'}>{value || 'Empty fields'}</span>
              </StyledOptionContainer>
            </StyledDropdownOption>
          )
        }
      })
    }

    const allOptionsVisible = Object.values(filterOptions).every(visible => visible)
    return (
      <Dropdown isOpen={isAdvancedFilterDropdownVisible} toggle={toggleAdvancedFilterDropdownVisibility}>
        <DropdownToggle data-toggle="dropdown" tag="div">
          <StyledIcon className="fas fa-filter" css={styles.icon} />
        </DropdownToggle>
        {isAdvancedFilterDropdownVisible && (
          <StyledDropdownMenu css={styles.dropdownMenu} right={isLaterColumn}>
            <StyledDropdownOption css={styles.dropdownOption}>
              <StyledInput placeholder="Search" type="search" css={styles.search} onChange={handleSearch} />
            </StyledDropdownOption>
            <StyledDropdownOption css={styles.dropdownOption}>
              <StyledInputContainer css={styles.inputContainer}>
                <Input type="checkbox" id="select-all" checked={allOptionsVisible} onChange={handleSelectAll} />
              </StyledInputContainer>
              <StyledOptionContainer css={styles.optionContainer}>
                <span title="Select All">Select All</span>
              </StyledOptionContainer>
            </StyledDropdownOption>
            {options}
          </StyledDropdownMenu>
        )}
      </Dropdown>
    )
  }, [
    columnsOptions.advancedFilter,
    isAdvancedFilterDropdownVisible,
    filterOptions,
    toggleAdvancedFilterDropdownVisibility,
    isLaterColumn,
    handleSearch,
    handleSelectAll,
    searchValue,
    handleInputChange,
  ])

  const sort = useMemo(
    () =>
      sortable && !isSwitchColumn ? (
        <StyledIcon
          className={`fas fa-${sortDirection}`}
          css={styles.sortableIcon(sortDirection)}
          {...column.getHeaderProps(column.getSortByToggleProps())}
        />
      ) : null,
    [sortable, sortDirection, column, isSwitchColumn]
  )

  const allFilterOptionsSelected = useMemo(
    () => Object.values(filterOptions).every(option => !!option),
    [filterOptions]
  )

  return (
    <StyledTableHeader
      className="table-th table-resizable-header"
      data-toggle="tooltip"
      title={column.Header}
      key={column.id}
      css={styles.tableHeader(isSomeColumnFilterable)}
    >
      <StyledHeaderContent css={styles.headerContent(isSwitchColumn)}>
        <StyledHeaderText css={styles.headerText(allFilterOptionsSelected)}>{column.render('Header')}</StyledHeaderText>
        {isTooltip && (
          <StyledToolbar css={styles.toolbar}>
            {advancedFilter}
            {sort}
          </StyledToolbar>
        )}
      </StyledHeaderContent>
      {!!columnFilter && !isSwitchColumn ? <div>{columnFilter}</div> : null}
    </StyledTableHeader>
  )
}

export { ColumnHeader }
