import { Container } from 'reactstrap'
import PropTypes from 'prop-types'

const Footer = ({ isDefault, fluid }) => {
  return (
    <footer className={`footer${isDefault ? ' default' : ''}`}>
      <Container fluid={fluid}>
        <nav>
          <ul>
            <li>
              <a href="https://futureconnections.com" className="mr-4-px" target="_blank" rel="noreferrer">
                Future Connections
              </a>
            </li>
            <li>
              <a href="https://futureconnections.com" className="mr-4-px" target="_blank" rel="noreferrer">
                About Us
              </a>
            </li>
            <li>
              <a href="https://futureconnections.com/es/blog/" target="_blank" rel="noreferrer">
                Blog
              </a>
            </li>
          </ul>
        </nav>
        <div className="copyright">
          &copy; {1900 + new Date().getYear()}, Developed by&nbsp;
          <a href="https://futureconnections.com" target="_blank" rel="noreferrer">
            Future Connections
          </a>
          .
        </div>
      </Container>
    </footer>
  )
}

Footer.defaultProps = {
  isDefault: false,
  fluid: false,
}

Footer.propTypes = {
  isDefault: PropTypes.bool,
  fluid: PropTypes.bool,
}

export { Footer }
